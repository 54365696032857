<template>
  <div class="h-96 overflow-y-auto">
    <div class="grid grid-cols-1 gap-4 px-4 py-4">
      <button
        @click="changeTalk(undefined)"
        class="btn btn-primary btn-small outline"
      >
        <i class="fas fa-plus mr-2 text-white"></i>
        Nova conversa
      </button>

      <div
        v-for="talk in talkList" :key="talk.id"
        class="talk relative"
        :class="{'active': activeTalkId === talk.id}"
        @click="changeTalk(talk.id)"
        :title="talk.name"
        >
        <i class="far fa-comment-alt mt-1"></i>
        <div
          v-if="!talk.editing"
          class="flex-1 truncate relative"
        >
          <div>{{ talk.name }}</div>
          <!-- <small v-if="talk.metadata">{{ talk.metadata.task }} - {{ talk.metadata.target }}</small> -->
          <!-- <div class="absolute inset-y-0 right-0 w-8 z-10 bg-gradient-to-l from-white"></div> -->
        </div>
        <div
          v-if="talk.editing"
          class="flex-1"
        >
          <form ref="theForm" class="flex items-center gap-2" @submit.prevent="renameTalk">
            <input class="py-0 px-2 w-full" type="text" :value="talk.name">
            <div
              class="flex gap-2"
              :class="{'hidden': activeTalkId !== talk.id, 'block': activeTalkId === talk.id}"
            >
              <button type="submit">
                <i class="fas fa-check cursor-pointer"></i>
              </button>
              <button @click="talk.editing = false">
                <i class="fas fa-times cursor-pointer"></i>
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="!talk.editing"
          class="flex gap-2"
          :class="{'hidden': activeTalkId !== talk.id, 'block': activeTalkId === talk.id}"
        >
          <button @click="talk.editing = true">
            <i class="fas fa-pencil-alt cursor-pointer"></i>
          </button>
          <button @click="deleteTalk(talk.id)">
            <i class="far fa-trash-alt cursor-pointer"></i>
          </button>
        </div>
      </div>

      <div
        v-if="!talkList.length"
        class="text-center text-gray-500"
      >
        A sua lista de conversas anteriores serão mostradas aqui.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { defineComponent, PropType, ref } from 'vue'

interface TalkInterface {
  id: string
  name: string
  editing?: boolean
  metadata?: any
}

export default defineComponent({

  props: {
    talkList: {
      type: Array as PropType<TalkInterface[]>,
      default: () => []
    },
    activeTalkId: {
      type: String,
      default: undefined
    },
  },

  emits: [
    'changeTalk',
    'deleteTalk',
    'renameTalk'
  ],

  setup (props, {emit}) {
    const theForm = ref<HTMLFormElement | null>(null)
    
    const changeTalk = (id: string | undefined) => {
      emit('changeTalk', id)
    }

    const deleteTalk = (id: string) => {
      emit('deleteTalk', id)
    }

    const renameTalk = (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      const inputName = element.querySelector('input[type="text"]') as HTMLInputElement
      const name = inputName?.value

      props.talkList.forEach(talk => {
        if (talk.editing) {
          talk.editing = false
          if (name.trim()) {
            talk.name = name
            emit('renameTalk', {id: talk.id, name})
          }
        }
      })
    }

    const submitRenameForm = () => {
      if (theForm.value) {
        theForm.value.submit()
      }
    }

    return {
      theForm,
      changeTalk,
      deleteTalk,
      renameTalk,
      submitRenameForm
    }
  }
})
</script>

<style scoped>
@layer components {
  .talk {
    @apply transition duration-200 ease-in-out no-underline;
    @apply flex justify-start items-start gap-2 px-2 py-2 text-gray-600 text-sm font-semibold rounded cursor-pointer;
  }

  .talk > div {
    @apply text-gray-600;
  }

  .talk i {
    @apply transition duration-200 ease-in-out no-underline;
    @apply text-gray-600;
  }

  .talk:hover {
    @apply bg-gray-600 text-gray-800 bg-opacity-5 no-underline;
    @apply ring-1 ring-gray-200;
  }

  .talk:hover i, .talk:hover div {
    @apply text-gray-800
  }

  .talk.active {
    @apply text-blue-500 bg-blue-600 bg-opacity-10 ring-1 ring-blue-200 hover:no-underline;
  }

  .talk.active > div {
    @apply text-blue-500;
  }

  .active i, .talk.active i {
    @apply text-blue-500;
  }
}
</style>
