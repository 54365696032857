import { ref } from "vue"
import { useI18n } from "vue-i18n"

interface InputProps {
  [key: string]: {
    prefix: string
    placeholder: string
  }
}

interface InputOptionsInterface {
  [key: string]: InputProps
}

interface TaskOptionsInterface {
  [key: string]: any
}

const input: InputOptionsInterface = {
  CreateSocialPost: {
    instagram: {
      prefix: 'page.manybot.model.create_social_post.instagram.prefix',
      placeholder: 'page.manybot.model.create_social_post.instagram.placeholder'
    },
    facebook: {
      prefix: 'page.manybot.model.create_social_post.facebook.prefix',
      placeholder: 'page.manybot.model.create_social_post.facebook.placeholder'
    },
    linkedin: {
      prefix: 'page.manybot.model.create_social_post.linkedin.prefix',
      placeholder: 'page.manybot.model.create_social_post.linkedin.placeholder'
    },
    twitter: {
      prefix: 'page.manybot.model.create_social_post.twitter.prefix',
      placeholder: 'page.manybot.model.create_social_post.twitter.placeholder'
    }
  },
  CreateVideoScript: {
    youtube: {
      prefix: 'page.manybot.model.create_video_script.youtube.prefix',
      placeholder: 'page.manybot.model.create_video_script.youtube.placeholder'
    },
    tiktok: {
      prefix: 'page.manybot.model.create_video_script.tiktok.prefix',
      placeholder: 'page.manybot.model.create_video_script.tiktok.placeholder'
    },
    shorts: {
      prefix: 'page.manybot.model.create_video_script.shorts.prefix',
      placeholder: 'page.manybot.model.create_video_script.shorts.placeholder'
    },
  },
  CreateHeadline: {
    youtube: {
      prefix: 'page.manybot.model.create_headline.youtube.prefix',
      placeholder: 'page.manybot.model.create_headline.youtube.placeholder'
    },
    instagram: {
      prefix: 'page.manybot.model.create_headline.instagram.prefix',
      placeholder: 'page.manybot.model.create_headline.instagram.placeholder'
    },
    facebook: {
      prefix: 'page.manybot.model.create_headline.facebook.prefix',
      placeholder: 'page.manybot.model.create_headline.facebook.placeholder'
    },
    linkedin: {
      prefix: 'page.manybot.model.create_headline.linkedin.prefix',
      placeholder: 'page.manybot.model.create_headline.linkedin.placeholder'
    },
    blog: {
      prefix: 'page.manybot.model.create_headline.blog.prefix',
      placeholder: 'page.manybot.model.create_headline.blog.placeholder'
    },
    idea: {
      prefix: 'page.manybot.model.create_headline.idea.prefix',
      placeholder: 'page.manybot.model.create_headline.idea.placeholder'
    },
  },
  CreateBlogArticle: {
    blog: {
      prefix: 'page.manybot.model.create_article.blog.prefix',
      placeholder: 'page.manybot.model.create_article.blog.placeholder'
    },
  }
}

const options: TaskOptionsInterface = {
  size: {
    label: 'page.manybot.model.options.size_words.label',
    placeholder: 'page.manybot.model.options.size_words.placeholder',
    value: ''
  },
  specialization: {
    label: 'specialization',
    placeholder: '',
    value: ''
  },
  audience: {
    label: 'page.manybot.model.options.audience.label',
    placeholder: 'page.manybot.model.options.audience.placeholder',
    value: ''
  },
  voiceTone: {
    label: 'page.manybot.model.options.voice_tone.label',
    placeholder: 'page.manybot.model.options.voice_tone.placeholder',
    value: ''
  },
  focus: {
    label: 'focus',
    placeholder: '',
    value: ''
  },
  goal: {
    label: 'page.manybot.model.options.goal.label',
    placeholder: 'page.manybot.model.options.goal.placeholder',
    value: ''
  },
  callToAction: {
    label: 'page.manybot.model.options.call_to_action.label',
    placeholder: 'page.manybot.model.options.call_to_action.placeholder',
    value: ''
  },
  keywords: {
    label: 'page.manybot.model.options.keywords.label',
    placeholder: 'page.manybot.model.options.keywords.placeholder',
    value: ''
  },
  count: {
    label: 'page.manybot.model.options.count.label',
    placeholder: 'page.manybot.model.options.count.placeholder',
    value: ''
  },
}

export const useManybot = () => {
  const { t } = useI18n()

  const inputOptions = ref({
    prefix: '',
    placeholder: ''
  })

  const taskOptions = ref<any[]>([])

  const setAvailableOptionsToTask = ({task, target}: {task: string, target: string}) => {
    const addOptions = (keys: string[]) => {
      keys.map((key) => {
        taskOptions.value.push({ key: key, ...options[key] })
      })
    }

    if (['youtube', 'tiktok', 'shorts'].includes(target)) {
      options.size.label = 'page.manybot.model.options.size_time.label'
      options.size.placeholder = 'page.manybot.model.options.size_time.placeholder'
    } else {
      options.size.label = 'page.manybot.model.options.size_words.label'
      options.size.placeholder = 'page.manybot.model.options.size_words.placeholder'
    }

    switch (task) {
      case 'CreateSocialPost':
        addOptions(['audience', 'voiceTone', 'goal'])
        break;
      case 'CreateVideoScript':
        addOptions(['audience', 'voiceTone', 'size'])
        break;
      case 'CreateHeadline':
        addOptions(['audience', 'count'])
        break;
      case 'CreateBlogArticle':
        addOptions(['keywords', 'goal', 'voiceTone', 'size'])
        break;
    }
  }

  const setInputProps = ({task, target}: {task: string, target: string}) => {
    inputOptions.value.prefix = t(input[task][target].prefix)
    inputOptions.value.placeholder = t(input[task][target].placeholder)
  }

  const initOptions = ({task, target}: {task: string, target: string}) => {
    setAvailableOptionsToTask({task, target})
    setInputProps({task, target})
  }

  return {
    inputOptions,
    taskOptions,
    initOptions,
    setAvailableOptionsToTask,
    setInputProps
  }
}