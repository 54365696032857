<template>
  <div class="h-96 overflow-y-auto">
    <div class="grid grid-cols-1 gap-4 px-4 py-4">
      <button
        @click="changeTalk(undefined)"
        class="btn btn-primary btn-small outline"
      >
        <i class="fas fa-plus mr-2 text-white"></i>
        Nova conversa
      </button>

      <div
        v-for="talk in talkList" :key="talk.id"
        class="talk relative"
        :class="{'active': activeTalkId === talk.id}"
        @click="changeTalk(talk.id)"
        :title="talk.name"
        >
        <i class="far fa-comment-alt mt-1"></i>
        <div
          v-if="!talk.editing"
          class="flex-1 truncate relative"
        >
          <div>{{ talk.name }}</div>
          <!-- <small v-if="talk.metadata">{{ talk.metadata.task }} - {{ talk.metadata.target }}</small> -->
          <!-- <div class="absolute inset-y-0 right-0 w-8 z-10 bg-gradient-to-l from-white"></div> -->
        </div>
        <div
          v-if="talk.editing"
          class="flex-1"
        >
          <form ref="theForm" class="flex items-center gap-2" @submit.prevent="renameTalk">
            <input class="py-0 px-2 w-full" type="text" :value="talk.name">
            <div
              class="flex gap-2"
              :class="{'hidden': activeTalkId !== talk.id, 'block': activeTalkId === talk.id}"
            >
              <button type="submit">
                <i class="fas fa-check cursor-pointer"></i>
              </button>
              <button @click="talk.editing = false">
                <i class="fas fa-times cursor-pointer"></i>
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="!talk.editing"
          class="flex gap-2"
          :class="{'hidden': activeTalkId !== talk.id, 'block': activeTalkId === talk.id}"
        >
          <button @click="talk.editing = true">
            <i class="fas fa-pencil-alt cursor-pointer"></i>
          </button>
          <button @click="deleteTalk(talk.id)">
            <i class="far fa-trash-alt cursor-pointer"></i>
          </button>
        </div>
      </div>

      <div
        v-if="!talkList.length"
        class="text-center text-gray-500"
      >
        A sua lista de conversas anteriores serão mostradas aqui.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { defineComponent, PropType, ref } from 'vue'

interface TalkInterface {
  id: string
  name: string
  editing?: boolean
  metadata?: any
}

export default defineComponent({

  props: {
    talkList: {
      type: Array as PropType<TalkInterface[]>,
      default: () => []
    },
    activeTalkId: {
      type: String,
      default: undefined
    },
  },

  emits: [
    'changeTalk',
    'deleteTalk',
    'renameTalk'
  ],

  setup (props, {emit}) {
    const theForm = ref<HTMLFormElement | null>(null)
    
    const changeTalk = (id: string | undefined) => {
      emit('changeTalk', id)
    }

    const deleteTalk = (id: string) => {
      emit('deleteTalk', id)
    }

    const renameTalk = (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      const inputName = element.querySelector('input[type="text"]') as HTMLInputElement
      const name = inputName?.value

      props.talkList.forEach(talk => {
        if (talk.editing) {
          talk.editing = false
          if (name.trim()) {
            talk.name = name
            emit('renameTalk', {id: talk.id, name})
          }
        }
      })
    }

    const submitRenameForm = () => {
      if (theForm.value) {
        theForm.value.submit()
      }
    }

    return {
      theForm,
      changeTalk,
      deleteTalk,
      renameTalk,
      submitRenameForm
    }
  }
})
</script>

<style scoped>
.talk {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  border-radius: 0.25rem;
  cursor: pointer;
}
.talk > div {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}
.talk i {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
  text-decoration: none;
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
}
.talk:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
  --tw-bg-opacity: 0.05;
  text-decoration: none;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(228, 228, 231, var(--tw-ring-opacity));
}
.talk:hover i, .talk:hover div {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.talk.active {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.talk.active:hover {
  text-decoration: none;
}
.talk.active > div {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.active i, .talk.active i {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
</style>
