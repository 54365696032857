<template>
  <dashboard-content>
    <template v-slot:actions>
      <quota-usage
        v-if="currentHeadlineContentPack"
        :current="currentHeadlineContentPack.used"
        :limit="currentHeadlineContentPack.limit"
        :title="$t('sentence.used_credit')"
      />
    </template>

    <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
      <div class="card w-full lg:w-3/4 h-full">
        <div class="card-body p-0">
          <div
            class="flex gap-4 items-center justify-center px-4 py-8"
            v-if="_isLoadingTalk"
          >
            <i class="fas fa-spinner fa-spin"></i>
            <div>{{ $t('sentence.loading') }}</div>
          </div>

          <div
            class="grid grid-cols-1 divide-y"
            v-if="!_isLoadingTalk"
          >

            <div
              v-if="!messages?.length"
              class="flex gap-4 items-start p-4"
            >
              <img
                :src="require('@/assets/profile/manyrobot-profile.svg')"
                class="flex-none w-12 h-12 rounded-full object-cover shadow-lg"
              />
              <div class="mt-4">
                Olá, meu nome é <strong>Manybot</strong>, eu sou uma inteligência artificial baseada na tecnologia Manycontent, que é uma plataforma de criação de conteúdo avançada. Fui desenvolvido para criar conteúdo de qualidade para redes sociais e outros canais de comunicação, usando técnicas avançadas de processamento de linguagem natural. Com a ajuda da tecnologia Manycontent, posso criar conteúdo personalizado e envolvente para seus seguidores nas redes sociais. Se precisar de ajuda para criar conteúdo para suas redes sociais, é só me perguntar!
              </div>
            </div>

            <div
              class="flex gap-4 items-start p-4"
              v-for="(message, index) in messages" :key="index"
            >
              <img
                :src="message.role === 'user' ? profileImage : require('@/assets/profile/manyrobot-profile.svg')"
                class="flex-none w-12 h-12 rounded-full object-cover shadow-lg"
              />
              <div class="mt-4 whitespace-pre-wrap" v-html="message.content" />
            </div>
            
            <div class="flex gap-4 items-start p-4">
              <img
                :src="profileImage"
                class="flex-none w-12 h-12 rounded-full object-cover shadow-lg"
              />
              <form class="mt-2 w-full" @submit.prevent="sendMessage">
                <textarea
                  class="w-full border border-gray-400 rounded p-2 mb-2"
                  v-model="currentUserMessage"
                  :rows="3"
                >
                </textarea>
                <div class="flex justify-end">
                  <button
                    class="btn btn-primary"
                    :disabled="!canSendMessage"
                  >
                    <i class="fas fa-spinner fa-spin" v-if="_isSendingMessage"></i>
                    {{ _isSendingMessage ? 'Enviando' : 'Enviar' }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card w-full lg:w-1/4 h-full hidden lg:block">
        <ChatBotSidebar
          :talk-list="talkList"
          :active-talk-id="selectedTalk?.id"
          @change-talk="fetchTalk"
          @deleteTalk="deleteTalk"
          @renameTalk="renameTalk"
        />
      </div>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
// import ChatBotEditor from '@/components/chatbot/ChatBotEditor.vue';
import ChatBotSidebar from '@/components/chatbot/ChatBotSidebar.vue';
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import DashboardContent from '../components/dashboard/DashboardContent.vue'
import { ManybotChatGateway } from '@/services/ManybotChatGateway'
import { useProfile } from '@/composables/profile/useProfile';
import { getProfileImage } from '@/composables/profile/profileImage';
import { useToast } from '@/composables/useToast'
import localforage from 'localforage';
import { useManybot } from '@/composables/manybot/useManybot';
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import { useContentPack } from '@/composables/contentPack/useContentPack';

interface MessageInterface {
  role: string
  content: string
}

interface TalkInterface {
  id: string
  name: string
  messages?: MessageInterface[]
  metadata?: Object
}

interface SelectedTalkInterface {
  id: string
  name: string
  messages: MessageInterface[]
}

export default defineComponent({
  components: {
    DashboardContent,
    // ChatBotEditor,
    ChatBotSidebar,
    QuotaUsage
  },

  setup () {
    const _isSendingMessage = ref<boolean>(false)
    const _isLoadingTalk = ref<boolean>(false)
    const talkList = ref<TalkInterface[]>([])
    const selectedTalk = ref<SelectedTalkInterface | undefined>()
    const tempMessages = ref<MessageInterface[]>([])
    
    const currentUserMessage = ref<string>('')
    const manybotChatGateway = new ManybotChatGateway()

    const {
      profile,
    } = useProfile()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const {
      inputOptions,
      initOptions
    } = useManybot()

    const profileImage = computed(() => {
      return getProfileImage(unref(profile))
    })

    const messages = computed(() => {
      if (selectedTalk.value?.messages.length) {
        return selectedTalk.value?.messages  
      } else if(tempMessages.value.length) {
        return tempMessages.value
      }
      return []
    })

    const canSendMessage = computed(() => {
      return !_isSendingMessage.value && unref(currentUserMessage).trim() !== ''
    })

    const fetchTalkList = async () => {
      const talks = await manybotChatGateway.listTalks({
        profileId: unref(profile).id,
        // type: 'chat' // 'chat' | 'completion'
      })
      talks
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .map(talk => addTalkListItem(talk))
    }

    const addTalkListItem = (talk: TalkInterface, position: 'before' | 'after' = 'before') => {
      const IDs = talkList.value.map(talk => talk.id)
      if (!IDs.some(id => id === talk.id)) {
        const payload = {
          id: talk.id,
          name: talk.name,
          messages: talk.messages || [],
          ...(talk.metadata && {metadata: talk.metadata})
        }
        if (position === 'before') {
          talkList.value.unshift(payload)
        } else {
          talkList.value.push(payload)
        }
      }

    }

    const getUserContentByMetadata = (metadata: any) => {
      if(metadata) {
        initOptions({ task: metadata.task, target: metadata.target })
        const content = `${inputOptions.value.prefix} ${metadata.topic}`
        return {
          role: 'user',
          content: content
        }
      }
    }

    const fetchTalk = async (id: string) => {
      if (selectedTalk.value?.id === id) {
        return
      }

      if (id) {
        _isLoadingTalk.value = true
        const talk = await manybotChatGateway.fetchTalk({id, profileId: unref(profile).id})
        if (talk) {
          selectTalk(talk.id)
          const message = getUserContentByMetadata(talk.metadata)
          if (message) {
            selectedTalk.value?.messages.push(message)
          }

          selectedTalk.value?.messages.push(
            ...talk.messages
          )
        }
        _isLoadingTalk.value = false
      } else {
        unselectTalk()
      }
    }

    const unselectTalk = () => {
      selectedTalk.value = undefined
    }

    const selectTalk = (id: string) => {
      const talk = talkList.value.find(t => t.id === id)

      if (talk) {
        selectedTalk.value = {
          id: talk.id,
          name: talk.name,
          messages: talk.messages || []
        }
      }
    }

    const sendMessage = async () => {
      if (!canSendMessage.value) {
        return
      }

      _isSendingMessage.value = true

      try {
        const assistantMessage = await manybotChatGateway.sendMessage({
          ...(selectedTalk.value && {id: selectedTalk.value.id}),
          profileId: unref(profile).id,
          message: unref(currentUserMessage),
        })

        const newMessages = [
          {
            role: 'user',
            content: unref(currentUserMessage)
          },
          {
            role: 'assistant',
            content: assistantMessage.message
          }
        ]

        addTalkListItem({
          id: assistantMessage.id,
          name: assistantMessage.name,
        }, 'before')

        selectTalk(assistantMessage.id)

        selectedTalk.value?.messages.push(
          ...newMessages
        )
        
        currentUserMessage.value = ''

      } catch (error) {
        console.log(error)
        useToast.fire({
          icon: 'error',
          title: 'Ops!',
          text: 'Ouve um erro enviar a sua mensagem, você pode enviar de novo?'
        })
      } finally {
        _isSendingMessage.value = false
      }
    }
    
    const sendCommand = async (payload: any) => {
      _isSendingMessage.value = true
      
      try {
        const message = getUserContentByMetadata(payload)
        if (message) {
          tempMessages.value.push(message)
        }

        const assistantMessage = await manybotChatGateway.sendTask({
          profileId: unref(profile).id,
          ...payload,
        })

        const newMessages = [
          {
            role: 'assistant',
            content: assistantMessage.message
          }
        ]
        
        if (message) {
          tempMessages.value.push(message)
          newMessages.unshift(message)
        }

        addTalkListItem({
          id: assistantMessage.id,
          name: assistantMessage.name,
          ...(assistantMessage.metadata && {metadata: assistantMessage.metadata})
        }, 'before')

        selectTalk(assistantMessage.id)

        selectedTalk.value?.messages.push(
          ...newMessages
        )
        
        currentUserMessage.value = ''
        tempMessages.value = []

      } catch (error) {
        console.log(error)
        useToast.fire({
          icon: 'error',
          title: 'Ops!',
          text: 'Ouve um erro enviar a sua mensagem, você pode enviar de novo?'
        })
      } finally {
        _isSendingMessage.value = false
      }
    }

    const deleteTalk = async (id: string) => {
      await manybotChatGateway.deleteTalk(id, unref(profile).id)
      talkList.value = talkList.value.filter(talk => talk.id !== id)
    }

    const renameTalk = async ({id, name}: {id: string, name: string}) => {
      await manybotChatGateway.renameTalk({id, name, profileId: unref(profile).id})
    }

    const handleCurrentChatMessage = async () => {
      const currentChatMessage = await localforage.getItem('currentChatMessage')

      if (currentChatMessage) {
        await sendCommand(currentChatMessage)
        await localforage.removeItem('currentChatMessage')
      }

    }

    onMounted(async () => {
      await Promise.all([
        await fetchTalkList(),
        await handleCurrentChatMessage()
      ])
    })
    
    return {
      _isSendingMessage,
      _isLoadingTalk,
      profileImage,
      messages,
      canSendMessage,
      currentUserMessage,
      talkList,
      selectedTalk,
      currentHeadlineContentPack,
      fetchTalk,
      sendMessage,
      deleteTalk,
      renameTalk
    }
  }
})
</script>
