
// eslint-disable-next-line no-unused-vars
import { defineComponent, PropType, ref } from 'vue'

interface TalkInterface {
  id: string
  name: string
  editing?: boolean
  metadata?: any
}

export default defineComponent({

  props: {
    talkList: {
      type: Array as PropType<TalkInterface[]>,
      default: () => []
    },
    activeTalkId: {
      type: String,
      default: undefined
    },
  },

  emits: [
    'changeTalk',
    'deleteTalk',
    'renameTalk'
  ],

  setup (props, {emit}) {
    const theForm = ref<HTMLFormElement | null>(null)
    
    const changeTalk = (id: string | undefined) => {
      emit('changeTalk', id)
    }

    const deleteTalk = (id: string) => {
      emit('deleteTalk', id)
    }

    const renameTalk = (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      const inputName = element.querySelector('input[type="text"]') as HTMLInputElement
      const name = inputName?.value

      props.talkList.forEach(talk => {
        if (talk.editing) {
          talk.editing = false
          if (name.trim()) {
            talk.name = name
            emit('renameTalk', {id: talk.id, name})
          }
        }
      })
    }

    const submitRenameForm = () => {
      if (theForm.value) {
        theForm.value.submit()
      }
    }

    return {
      theForm,
      changeTalk,
      deleteTalk,
      renameTalk,
      submitRenameForm
    }
  }
})
