
// import ChatBotEditor from '@/components/chatbot/ChatBotEditor.vue';
import ChatBotSidebar from '@/components/chatbot/ChatBotSidebar.vue';
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import DashboardContent from '../components/dashboard/DashboardContent.vue'
import { ManybotChatGateway } from '@/services/ManybotChatGateway'
import { useProfile } from '@/composables/profile/useProfile';
import { getProfileImage } from '@/composables/profile/profileImage';
import { useToast } from '@/composables/useToast'
import localforage from 'localforage';
import { useManybot } from '@/composables/manybot/useManybot';
import QuotaUsage from '@/components/posts/QuotaUsage.vue'
import { useContentPack } from '@/composables/contentPack/useContentPack';

interface MessageInterface {
  role: string
  content: string
}

interface TalkInterface {
  id: string
  name: string
  messages?: MessageInterface[]
  metadata?: Object
}

interface SelectedTalkInterface {
  id: string
  name: string
  messages: MessageInterface[]
}

export default defineComponent({
  components: {
    DashboardContent,
    // ChatBotEditor,
    ChatBotSidebar,
    QuotaUsage
  },

  setup () {
    const _isSendingMessage = ref<boolean>(false)
    const _isLoadingTalk = ref<boolean>(false)
    const talkList = ref<TalkInterface[]>([])
    const selectedTalk = ref<SelectedTalkInterface | undefined>()
    const tempMessages = ref<MessageInterface[]>([])
    
    const currentUserMessage = ref<string>('')
    const manybotChatGateway = new ManybotChatGateway()

    const {
      profile,
    } = useProfile()

    const {
      currentHeadlineContentPack
    } = useContentPack()

    const {
      inputOptions,
      initOptions
    } = useManybot()

    const profileImage = computed(() => {
      return getProfileImage(unref(profile))
    })

    const messages = computed(() => {
      if (selectedTalk.value?.messages.length) {
        return selectedTalk.value?.messages  
      } else if(tempMessages.value.length) {
        return tempMessages.value
      }
      return []
    })

    const canSendMessage = computed(() => {
      return !_isSendingMessage.value && unref(currentUserMessage).trim() !== ''
    })

    const fetchTalkList = async () => {
      const talks = await manybotChatGateway.listTalks({
        profileId: unref(profile).id,
        // type: 'chat' // 'chat' | 'completion'
      })
      talks
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .map(talk => addTalkListItem(talk))
    }

    const addTalkListItem = (talk: TalkInterface, position: 'before' | 'after' = 'before') => {
      const IDs = talkList.value.map(talk => talk.id)
      if (!IDs.some(id => id === talk.id)) {
        const payload = {
          id: talk.id,
          name: talk.name,
          messages: talk.messages || [],
          ...(talk.metadata && {metadata: talk.metadata})
        }
        if (position === 'before') {
          talkList.value.unshift(payload)
        } else {
          talkList.value.push(payload)
        }
      }

    }

    const getUserContentByMetadata = (metadata: any) => {
      if(metadata) {
        initOptions({ task: metadata.task, target: metadata.target })
        const content = `${inputOptions.value.prefix} ${metadata.topic}`
        return {
          role: 'user',
          content: content
        }
      }
    }

    const fetchTalk = async (id: string) => {
      if (selectedTalk.value?.id === id) {
        return
      }

      if (id) {
        _isLoadingTalk.value = true
        const talk = await manybotChatGateway.fetchTalk({id, profileId: unref(profile).id})
        if (talk) {
          selectTalk(talk.id)
          const message = getUserContentByMetadata(talk.metadata)
          if (message) {
            selectedTalk.value?.messages.push(message)
          }

          selectedTalk.value?.messages.push(
            ...talk.messages
          )
        }
        _isLoadingTalk.value = false
      } else {
        unselectTalk()
      }
    }

    const unselectTalk = () => {
      selectedTalk.value = undefined
    }

    const selectTalk = (id: string) => {
      const talk = talkList.value.find(t => t.id === id)

      if (talk) {
        selectedTalk.value = {
          id: talk.id,
          name: talk.name,
          messages: talk.messages || []
        }
      }
    }

    const sendMessage = async () => {
      if (!canSendMessage.value) {
        return
      }

      _isSendingMessage.value = true

      try {
        const assistantMessage = await manybotChatGateway.sendMessage({
          ...(selectedTalk.value && {id: selectedTalk.value.id}),
          profileId: unref(profile).id,
          message: unref(currentUserMessage),
        })

        const newMessages = [
          {
            role: 'user',
            content: unref(currentUserMessage)
          },
          {
            role: 'assistant',
            content: assistantMessage.message
          }
        ]

        addTalkListItem({
          id: assistantMessage.id,
          name: assistantMessage.name,
        }, 'before')

        selectTalk(assistantMessage.id)

        selectedTalk.value?.messages.push(
          ...newMessages
        )
        
        currentUserMessage.value = ''

      } catch (error) {
        console.log(error)
        useToast.fire({
          icon: 'error',
          title: 'Ops!',
          text: 'Ouve um erro enviar a sua mensagem, você pode enviar de novo?'
        })
      } finally {
        _isSendingMessage.value = false
      }
    }
    
    const sendCommand = async (payload: any) => {
      _isSendingMessage.value = true
      
      try {
        const message = getUserContentByMetadata(payload)
        if (message) {
          tempMessages.value.push(message)
        }

        const assistantMessage = await manybotChatGateway.sendTask({
          profileId: unref(profile).id,
          ...payload,
        })

        const newMessages = [
          {
            role: 'assistant',
            content: assistantMessage.message
          }
        ]
        
        if (message) {
          tempMessages.value.push(message)
          newMessages.unshift(message)
        }

        addTalkListItem({
          id: assistantMessage.id,
          name: assistantMessage.name,
          ...(assistantMessage.metadata && {metadata: assistantMessage.metadata})
        }, 'before')

        selectTalk(assistantMessage.id)

        selectedTalk.value?.messages.push(
          ...newMessages
        )
        
        currentUserMessage.value = ''
        tempMessages.value = []

      } catch (error) {
        console.log(error)
        useToast.fire({
          icon: 'error',
          title: 'Ops!',
          text: 'Ouve um erro enviar a sua mensagem, você pode enviar de novo?'
        })
      } finally {
        _isSendingMessage.value = false
      }
    }

    const deleteTalk = async (id: string) => {
      await manybotChatGateway.deleteTalk(id, unref(profile).id)
      talkList.value = talkList.value.filter(talk => talk.id !== id)
    }

    const renameTalk = async ({id, name}: {id: string, name: string}) => {
      await manybotChatGateway.renameTalk({id, name, profileId: unref(profile).id})
    }

    const handleCurrentChatMessage = async () => {
      const currentChatMessage = await localforage.getItem('currentChatMessage')

      if (currentChatMessage) {
        await sendCommand(currentChatMessage)
        await localforage.removeItem('currentChatMessage')
      }

    }

    onMounted(async () => {
      await Promise.all([
        await fetchTalkList(),
        await handleCurrentChatMessage()
      ])
    })
    
    return {
      _isSendingMessage,
      _isLoadingTalk,
      profileImage,
      messages,
      canSendMessage,
      currentUserMessage,
      talkList,
      selectedTalk,
      currentHeadlineContentPack,
      fetchTalk,
      sendMessage,
      deleteTalk,
      renameTalk
    }
  }
})
